import {NavLink, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import i18n from "./i18n";



const Navbar = () => {
  const {t} = useTranslation();
  // console.log('locale', i18n.language);
  return (
    <div>
      {/*<div>*/}
      {/*  <NavLinkSetLanguage locale="pl">PL</NavLinkSetLanguage>*/}
      {/*  {' | '}*/}
      {/*  <NavLinkSetLanguage locale="uk">UA</NavLinkSetLanguage>*/}
      {/*</div>*/}
      {/*<NavLinkLocale to="/">{t('Home')}</NavLinkLocale>*/}
      {/*<NavLinkLocale to="/page1">{t('Page1')}</NavLinkLocale>*/}
      {/*<NavLinkLocale to="/page2">{t('Page2')}</NavLinkLocale>*/}
    </div>
  );
}

export default Navbar;
