import loadable from '@loadable/component'
import {Helmet} from 'react-helmet';
import styles from '../styles/Home.module.scss'
import React from "react";
import HrSeparator from "../components/HrSeparator/HrSeparator";
import {useTranslation} from "react-i18next";

const Hero = loadable(() => import('../components/Sections/Hero/Hero'));
const Contact = loadable(() => import('../components/Sections/Contact/Contact'));


const Home = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('home.title')}</title>
        <meta name="description" content={t('home.description')} />
        <meta name="keywords" content={t('home.keywords')} />
      </Helmet>
      <main>
        <Hero className={styles.HomeHeroSection} />
        <HrSeparator />
        <Contact />
        <HrSeparator />
      </main>
    </>
  )
}


export default Home
